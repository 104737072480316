export enum ROUTES {
  REPORT = 'reporte-credito',
  LOAN_INPUT = 'loan-input',
  CREDIT_FLOW_REGISTER = 'registro-abonos',
  GENERATE_REPORT = 'generar-reporte',
  MASTER_SERVICING = 'servicio-maestro',
  MASTER_SERVICING_CDD = 'servicio-maestro/cdd',
  MASTER_SERVICING_CRB = 'servicio-maestro/crb',
  MASTER_SERVICING_COB = 'servicio-maestro/cob',
  MASTER_SERVICING_HISTORY = 'servicio-maestro/history',
}
