import { version } from '../../package.json';

export const showVersion = () => {
  console.info(
    `%c v${version} `,
    `
    background-color: #49E295; 
    color: #1F2D27;
    padding: 4px; 
    border-radius: 1em;
    `,
  );
};
