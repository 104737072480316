interface IndexableMf {
  NOT_FOUND: string;
  LOAN_INPUT: string;
  REPORT_GENERATOR: string;
  MASTER_SERVICING: string;
  MASTER_SERVICING_CRB: string;
  [key: string]: string;
}
export const MFs: IndexableMf = {
  NOT_FOUND: `https://assets.creditas.com/mx-notfound-mf/${process.env.REACT_APP_BUILD_ENV}/creditasmx-notfound-mf.js`,
  LOAN_INPUT: `https://assets.creditas.com/mx-loan-input-mf/${process.env.REACT_APP_BUILD_ENV}/mx-loan-input-mf.js`,
  REPORT_GENERATOR: `https://assets.creditas.com/mx-cdcreport-generate-mf/${process.env.REACT_APP_BUILD_ENV}/mx-cdcreport-generate.js`,
  CREDIT_FLOW_REGISTER: `https://assets.creditas.com/mx-credit-flow-mf/${process.env.REACT_APP_BUILD_ENV}/mx-credit-flow-mf.js`,
  MASTER_SERVICING: `https://assets.creditas.com/mx-cxc-mf/${process.env.REACT_APP_BUILD_ENV}/mx-cxc-mf.js`,
  MASTER_SERVICING_CRB: `https://assets.creditas.com/mx-follow-up-collection-mf/${process.env.REACT_APP_BUILD_ENV}/mx-follow-up-collection-mf.js`,
  MASTER_SERVICING_COB: `https://assets.creditas.com/mx-cob-mf/${process.env.REACT_APP_BUILD_ENV}/mx-cob-mf.js`,
};

const array = new Uint32Array(1);
Object.keys(MFs).forEach((key) => {
  MFs[key] = MFs[key] + `?v=${window.crypto.getRandomValues(array)}`;
});
