/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseRoutes } from './baseRoutes';
import { ROUTES } from './routes';
import { navigateWithParams } from '@creditasmx-utilities/helpers';
import { MDO_USER_PROFILE_KEY } from './constants';
import { NavbarConfiguration } from './navbar.config';
import jwt_decode from 'jwt-decode';

interface InSectionItem {
  name: string;
  goToUrl: string;
  allowedGroups: string[];
}

const checkIfSectionIsAllowed = (sectionItem: InSectionItem): boolean => {
  const accessToken = JSON.parse(
    localStorage.getItem(MDO_USER_PROFILE_KEY) || '',
  ).access_token;

  if (accessToken) {
    const decodedToken = jwt_decode(accessToken || '') as any;
    const tokenGoogleGroups = decodedToken?.google_groups as string[];
    return sectionItem?.allowedGroups.some((allowedGroup) =>
      tokenGoogleGroups.some(
        (tokenGoogleGroup) =>
          tokenGoogleGroup === allowedGroup || allowedGroup === '*',
      ),
    );
  }
  return false;
};

const prefix = (location: Location, ...prefixes: string[]): boolean =>
  prefixes.some((prefix) => location?.pathname.includes(prefix));

const main = (location: Location): boolean => {
  if (
    location.pathname === BaseRoutes.MDO ||
    location.pathname === BaseRoutes.MDO.substr(0, BaseRoutes.MDO.length - 1) ||
    location.pathname === '/'
  )
    navigateWithParams(BaseRoutes.MDO);
  return true;
};

const mfActivityReport = (location: Location): boolean =>
  prefix(location, BaseRoutes.MDO + ROUTES.REPORT) &&
  checkIfSectionIsAllowed(NavbarConfiguration[0].items[0]);

const mfActivityLoanInput = (location: Location): boolean =>
  prefix(location, BaseRoutes.MDO + ROUTES.LOAN_INPUT);

const mfReportGenerator = (location: Location): boolean =>
  prefix(location, BaseRoutes.MDO + ROUTES.GENERATE_REPORT) &&
  checkIfSectionIsAllowed(NavbarConfiguration[0].items[1]);

const mfCreditFlow = (location: Location): boolean =>
  prefix(location, BaseRoutes.MDO + ROUTES.CREDIT_FLOW_REGISTER) &&
  checkIfSectionIsAllowed(NavbarConfiguration[1].items[1]);

const mfMasterServicingCdd = (location: Location): boolean =>
  prefix(location, BaseRoutes.MDO + ROUTES.MASTER_SERVICING_CDD) &&
  checkIfSectionIsAllowed(NavbarConfiguration[1].items[2]);

const mfMasterServicingCrb = (location: Location): boolean =>
  prefix(location, BaseRoutes.MDO + ROUTES.MASTER_SERVICING_CRB) &&
  checkIfSectionIsAllowed(NavbarConfiguration[1].items[3]);

const mfMasterServicingCob = (location: Location): boolean =>
  prefix(location, BaseRoutes.MDO + ROUTES.MASTER_SERVICING_COB) &&
  checkIfSectionIsAllowed(NavbarConfiguration[1].items[4]);

const mfMasterServicingHistory = (location: Location): boolean =>
  prefix(location, BaseRoutes.MDO + ROUTES.MASTER_SERVICING_HISTORY) &&
  checkIfSectionIsAllowed(NavbarConfiguration[1].items[5]);

export default {
  main,
  mfActivityReport,
  mfActivityLoanInput,
  mfReportGenerator,
  mfCreditFlow,
  mfMasterServicingCdd,
  mfMasterServicingCrb,
  mfMasterServicingCob,
  mfMasterServicingHistory,
};
