/* eslint-disable @typescript-eslint/no-explicit-any */
import { proxy } from 'valtio';

export const MDO_USER_PROFILE_KEY = 'mdoUserProfile';

const globalState = proxy({
  userProfile: {},

  setUserProfile(userProfile: any) {
    globalState.userProfile = userProfile;
  },
});

export default globalState;
