import { start, registerApplication, LifeCycles } from 'single-spa';
import Activity from './activityFns';
import globalState, { MDO_USER_PROFILE_KEY } from './constants';
import { NavbarConfiguration } from './navbar.config';
import { MFs } from './microfrontendsRegister';
import { ROUTES } from './routes';
import { BaseRoutes } from './baseRoutes';
import { showVersion } from './utilities/showVersion';

const registerApps = (): void => {
  registerApplication(
    'main',
    () => import('./microfrontends/app.lifecycles'),
    Activity.main,
    { globalState, userProfileKey: MDO_USER_PROFILE_KEY },
  );
  registerApplication(
    'mfNavbar',
    () => import('./microfrontends/mfNavbar/mfNavbar.lifecycle'),
    (): true => true,
    { globalState, config: NavbarConfiguration },
  );
  registerApplication(
    'mfFooter',
    () => import('./microfrontends/mfFooter/mfFooter.lifecycle'),
    (): true => true,
  );

  registerApplication(
    'mfReport',
    () => import('./microfrontends/mfReport/mfReport.lifecycle'),
    Activity.mfActivityReport,
    { userProfileKey: MDO_USER_PROFILE_KEY },
  );
  registerApplication(
    'mfLoanInput',
    () =>
      System.import(MFs.LOAN_INPUT) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.mfActivityLoanInput,
    {
      domElementId: 'renderContainerId',
      basedRoute: `${BaseRoutes.MDO}${ROUTES.LOAN_INPUT}`,
      userProfileKey: MDO_USER_PROFILE_KEY,
    },
  );
  registerApplication(
    'mfReportGenerator',
    () =>
      System.import(MFs.REPORT_GENERATOR) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.mfReportGenerator,
    {
      domElementId: 'renderContainerId',
    },
  );
  registerApplication(
    'mfCreditFlow',
    () =>
      System.import(MFs.CREDIT_FLOW_REGISTER) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.mfCreditFlow,
    {
      domElementId: 'renderContainerId',
    },
  );

  registerApplication(
    'mfMasterServicingCdd',
    () =>
      System.import(MFs.MASTER_SERVICING) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.mfMasterServicingCdd,
    {
      domElementId: 'renderContainerId',
      basedRoute: `${BaseRoutes.MDO}${ROUTES.MASTER_SERVICING}`,
    },
  );

  registerApplication(
    'mfMasterServicingCrb',
    () =>
      System.import(MFs.MASTER_SERVICING_CRB) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.mfMasterServicingCrb,
    {
      domElementId: 'renderContainerId',
    },
  );

  registerApplication(
    'mfMasterServicingCob',
    () =>
      System.import(MFs.MASTER_SERVICING_COB) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.mfMasterServicingCob,
    {
      domElementId: 'renderContainerId',
    },
  );

  registerApplication(
    'mfMasterServicingHistory',
    () =>
      System.import(MFs.MASTER_SERVICING) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.mfMasterServicingHistory,
    {
      domElementId: 'renderContainerId',
      basedRoute: `${BaseRoutes.MDO}${ROUTES.MASTER_SERVICING}`,
    },
  );
};
registerApps();
showVersion();
start();
