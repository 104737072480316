import { BaseRoutes } from './baseRoutes';
import { ROUTES } from './routes';

export const NavbarConfiguration = [
  {
    sectionName: 'Crédito',
    items: [
      {
        name: 'Reporte de crédito',
        goToUrl: BaseRoutes.MDO + ROUTES.REPORT,
        allowedGroups: ['mx-mdo-credito@creditas.com'],
      },
      {
        name: 'Generador reportes',
        goToUrl: BaseRoutes.MDO + ROUTES.GENERATE_REPORT,
        allowedGroups: ['mx-mdo-credito@creditas.com'],
      },
    ],
  },
  {
    sectionName: 'FinOps',
    items: [
      {
        name: 'Loan Input',
        goToUrl: BaseRoutes.MDO + ROUTES.LOAN_INPUT,
        allowedGroups: ['mx-mdo-loan-input@creditas.com'],
      },
      {
        name: 'Registro de Flujo de Abonos',
        goToUrl: BaseRoutes.MDO + ROUTES.CREDIT_FLOW_REGISTER,
        allowedGroups: ['mx-master-servicer@creditas.com'],
      },
      {
        name: 'Servicio Maestro - CDD',
        goToUrl: BaseRoutes.MDO + ROUTES.MASTER_SERVICING_CDD,
        allowedGroups: ['mx-master-servicer@creditas.com'],
      },
      {
        name: 'Servicio Maestro - CRB',
        goToUrl: BaseRoutes.MDO + ROUTES.MASTER_SERVICING_CRB,
        allowedGroups: ['mx-master-servicer@creditas.com'],
      },
      {
        name: 'Servicio Maestro - COB',
        goToUrl: BaseRoutes.MDO + ROUTES.MASTER_SERVICING_COB,
        allowedGroups: ['mx-master-servicer@creditas.com'],
      },
      {
        name: 'Descarga de envíos',
        goToUrl: BaseRoutes.MDO + ROUTES.MASTER_SERVICING_HISTORY,
        allowedGroups: ['mx-master-servicer@creditas.com'],
      },
    ],
  },
];
